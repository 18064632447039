@tailwind base;

@layer base {
    body {
        @apply m-0;
        @apply text-white;
        @apply bg-black;
        @apply bg-fixed;
    }
}

@tailwind components;
@tailwind utilities;

